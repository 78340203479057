import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/ffsdgroup.png'
import CsrSection from '../../components/CsrSection/CsrSection';


const CsrPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Corporate Society Responsility'} pagesub={'Csr'}/> 
            <CsrSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default CsrPage;

