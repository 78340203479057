import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
import simg3 from '../images/service-single/img-3.jpg'
import simg4 from '../images/service-single/img-4.jpg'
import simg5 from '../images/service-single/img-5.jpg'
import simg6 from '../images/service-single/img-3.jpg'


const Events = [
    {
        Id: '1',
        sImg:simg,
        sTitle:'10th Anniversary',
        slug:'10th-Anniversary',
        description:'At FFSD Group, we are about helping students fulfill their dreams of studying abroad. As a leader in education consulting, we offer free admission services to top universities worldwide, ensuring financial challenges don’t stop international students.',
        des2:'With strong university partnerships, we connect you to renowned institutions like Teesside University, Loughborough University, Cranfield University, and many others across the UK.',
        des3:'We provide end-to-end support, guiding you through every step of the process, from choosing the right program to visa applications.',
        des4:'Our personalized guidance ensures tailored solutions that align with your educational and career goals.',
        des5:'Since Dr. Paschal Ohalehi founded FFSD Group in 2012, we have helped thousands of students achieve their dream of studying abroad. We are committed to making world-class education accessible while simplifying the entire process for you.',
        des6:'Our mission is to make a lasting difference by creating exceptional experiences and delivering positive outcomes through our innovative services. Join the thousands of students who trust FFSD Group to support their study abroad journey. Let us help you unlock new opportunities and create a brighter future!',
        icon:'flaticon-gift',
        website: 'https://www.ffsd.ng/',
        phone: '+2348012345678'
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle:'Pre-departure Events',
        slug:'Pre-departure-Events',
        description:'At FFSD Group, we know how important it is to have authentic and reliable documents in today’s competitive world. That’s why we offer Document and Qualification Verification (DQV) services to ensure trust, simplify the process, and provide reliable solutions.',
        des2:'We accurately validate academic, professional, and legal documents to confirm their authenticity. We also verify educational and professional qualifications for employers, universities, and other organizations, helping to save time and simplify complex verification processes.',
        des3:'Our services are available across industries and countries, making FFSD Group a reliable partner wherever you are.',
        des4:'Our goal is to become a global leader in document and qualification verification by providing reliable, efficient, and secure services that build trust and confidence.',
        des5:'Whether you need to validate personal documents, verify qualifications for an institution, or ensure compliance for a business, FFSD Group is here to make the process smooth and stress-free. Trust FFSD Group’s DQV Services for professional, accurate, and dependable document verification.',
        des6:'',
        website: 'https://www.dqvplatform.com/',
        phone: '+2348012345678',
        icon:'flaticon-suitcase'
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Business Retreat',
        slug:'Business-Retreat',
        description:'At FFSD Group, we love helping you achieve your travel dreams. Through our FFSD Travels division, we offer stress-free, affordable, and personalized travel services for all types of trips—whether business, family or study abroad adventure.',
        des2:'We make booking flights easy and affordable, offering great deals for both local and international destinations. Our team provides expert advice to help you choose the best destinations and plan your itinerary. We also simplify the visa application process with step-by-step guidance. Plus, our customized travel packages are designed to suit your budget and make every trip unforgettable.',
        des3:'Our focus is on finding the best travel deals while ensuring top-quality service. Whether you’re planning a short getaway or a big international trip, we provide support tailored to your specific needs. From planning to booking and beyond, we’re with you every step of the way.',
        des4:'At FFSD Group, we aim to make traveling easy and enjoyable. Let us take care of the details so you can focus on creating amazing memories.',
        des5:'',
        des6:'',
        website: 'https://www.ffsdtravels.com/',
        phone: '+2348012345678',
        icon:'flaticon-stats',
    },
    {
        Id: '4',
        sImg:simg4,
        sTitle: 'Education Fair',
        slug:'Education-Fair',
        description:'At FFSD Group, we know that a great workspace is key to fostering creativity, collaboration, and success. That’s why we created Maypas Workspace a service offering premium, fully equipped spaces designed to meet the needs of businesses, freelancers, startups, and teams of all sizes.',
        des2:'Our workspace is tailored to fit your style and budget, whether you need a private office, a shared coworking space, or a meeting room. Each space is designed for productivity, featuring high-speed internet, ergonomic furniture, and all the amenities needed for a seamless work experience.',
        des3:'With flexible rental options, you can book a workspace for just a few hours, a full day, or even long term. Maypas Workspace also connects you with a vibrant community of professionals, creating opportunities for collaboration and growth.',
        des4:'Located in a convenient area, our workspace is accessible and affordable. With a simple booking process, you can focus on what truly matters—your work. Our dedicated team ensures every detail is taken care of, offering you a comfortable and functional environment every time.',
        des5:'',
        des6:'',
        website: 'https://www.maypasworkspace.com/',
        phone: '+2348012345678',
        icon:'flaticon-start',
    },
    // {
    //     Id: '5',
    //     sImg:simg5,
    //     sTitle:'Private Offices',
    //     slug:'Strategy-Marketing',
    //     description:'',
    //     des2:'',
    //     des3:'',
    //     icon:'flaticon-chess-piece',
    //     des4:'',
    //     des5:'',
    //     des6:'',
    //     website: 'https://www.ffsd.ng/',
    //     phone: '+2348012345678',
    // },
    // {
    //     Id: '6',
    //     sImg:simg6,
    //     sTitle:'Research Grant',
    //     slug:'Sustainability',
    //     description:'',
    //     des2:'',
    //     des3:'',
    //     icon:'flaticon-green-energy',
    //     des4:'',
    //     des5:'',
    //     des6:'',
    //     website: 'https://www.ffsd.ng/',
    //     phone: '+2348012345678',
    // }
]    

export default Events;