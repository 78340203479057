import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const SubmitHandler = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post(`ffsdgroup.com/api/login`, {
        email: username, // Assuming the API uses "email"
        password,
      });

      console.log(response)

      if (response.data?.token) {
        setSuccess('Login successful!');
        localStorage.setItem('token', response.data.token);
      }
    } catch (err) {
      setError('Invalid username or password.');
    }
  };

  return (
    <div className="mini-cart-items bg-dark p-4 rounded shadow">
      <form onSubmit={SubmitHandler}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            id="username"
            className="form-control"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {/* <div className="mini-btn bg-danger p-3 d-flex justify-content-center align-items-center"> */}
          <button type="submit" className="btn btn-primary mini-btn d-flex justify-content-center align-items-center bg-danger w-100" style={{ height: '40px' }}>
            Log In
          </button>
        {/* </div> */}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        {success && <div className="alert alert-success mt-3">{success}</div>}
      </form>
    </div>
  );
};

export default Login;
