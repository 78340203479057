import React from "react";
import { Link } from 'react-router-dom'


const Hero3 = () => {
    return (

        <section className="hero-slider hero-style-1 static-hero">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container">
                        <div data-swiper-parallax="300" className="slide-title">
                            <h3 className="text-white">FINANCIAL FORENSIC STUDIES AND DIAGNOSTICS</h3>
                        </div>
                        <div data-swiper-parallax="400" className="slide-text">
                            <p>We are dedicated to helping you reach your goals, whether you're exploring international study, planning unforgettable travels, or scaling your business.</p>
                        </div>
                        <div className="clearfix"></div>
                        <div data-swiper-parallax="500" className="slide-btns">
                            <Link to="/about" className="theme-btn">Discover More<span></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero3;