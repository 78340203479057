import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Login from "../Login/Login";

const Header = (props) => {
    const location = useLocation();
    
    const isActive = (path) => location.pathname.startsWith(path);

  const [menuActive, setMenuState] = useState(false);
  const [profileActive, setProfileState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const { carts } = props;

  return (
    <header id="header" className={`site-header ${props.hclass}`}>
      <nav className="navigation navbar navbar-expand-lg navbar-light">
        <div className="container">
          <div className="navbar-header">
            <MobileMenu />
            <Link onClick={ClickHandler} className="navbar-brand" to="/">
              <img src={props.Logo} alt="" />
            </Link>
          </div>
          <div
            id="navbar"
            className="collapse navbar-collapse navigation-holder"
          >
            <button className="close-navbar">
              <i className="ti-close"></i>
            </button>

            <ul className="nav navbar-nav mb-2 mb-lg-0">
              <li className="">
                <Link onClick={ClickHandler} to="/home" className={isActive("/home") ? "active-link" : ""}>
                  Home
                </Link>
              </li>
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="/about" className={isActive("/about") ? "active-link" : ""}>
                  About
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link onClick={ClickHandler} to="/about" >
                      Background
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/career" >
                      Career
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="/service" className={isActive("/service") ? "active-link" : ""}>
                  Services
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link onClick={ClickHandler} to={`/service/Study-Abroad`} >
                      Study Abroad
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to={`/service/DocumentVerification`} 
                    >
                      Document Verification
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={`/service/travels`} >
                      Travels and Tour
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={`/service/Workstation`} >
                      Workstation
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="/events" className={isActive("/events") ? "active-link" : ""}>
                  Events
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to={`/events/10th-Anniversary`}
                    >
                      10th Anniversary
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to={`/events/Pre-departure-Events`}
                    >
                      Pre-Departure Event
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to={`/events/Business-Retreat`}
                    >
                      Business Retreat
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={`/events/Education-Fair`}>
                      Education Fair
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link onClick={ClickHandler} to="/csr" className={isActive("/csr") ? "active-link" : ""}>
                  CSR
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link onClick={ClickHandler} to={`/csr/Research-Grant`} >
                      Research Grant
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={`/csr/Charity`} >
                      Charity
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="">
                <Link onClick={ClickHandler} to="" className={isActive("/blog") ? "active-link" : ""}>
                  Blog
                </Link>
              </li>
              <li>
                <Link onClick={ClickHandler} to="/contact" className={isActive("/contact") ? "active-link" : ""}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="cart-search-contact">
            <div className="mini-cart">
              <button
                className="cart-toggle-btn"
                onClick={() => setMenuState(!menuActive)}
              >
                <i
                  className={`fi ti-user ${menuActive ? "ti-close" : "fi "}`}
                ></i>
              </button>
              <div
                className={`mini-cart-content ${
                  menuActive ? "mini-cart-content-toggle" : ""
                }`}
              >
                <Login />
              </div>
            </div>

          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
// export default connect(mapStateToProps, { removeFromCart })(Header);
