import React from 'react'

const Features = (props) => {
    return (
        <section className="features-section">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="feature-grids clearfix">
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-sheriff"></i>
                                </div>
                                <h3>INTEGRITY</h3>
                                <p>We believe in honesty and transparency in everything we do. At FFSD Group, integrity is the foundation of our relationships, ensuring that every recommendation and service is driven by your best interests.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-diamond"></i>
                                </div>
                                <h3>PROFESSIONALISM</h3>
                                <p>Our team consists of seasoned experts who bring a high level of professionalism to every interaction. With FFSD Group, you can expect exceptional service, timely responses, and a commitment to excellence in all aspects of your journey.</p>
                            </div>
                            <div className="grid">
                                <div className="icon">
                                    <i className="fi flaticon-idea"></i>
                                </div>
                                <h3>TRUST</h3>
                                <p>Your confidence in us matters. We prioritize building trust by delivering reliable, high-quality services and maintaining open communication. With FFSD Group, you can feel secure knowing we are fully dedicated to your success.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features;