import React from "react";
import { Link } from 'react-router-dom'
import Csr from '../../api/csr'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const CsrSection = () => {
    return (
        <section className="services-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title-s3">
                            {/* <span>Services</span> */}
                            <h2>Some of our Corporate Social Responsibilities</h2>
                            {/* <p>Showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look </p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="services-grids clearfix">
                            {Csr.map((csr, srv) => (
                                <div className="grid" key={srv}>
                                    <Link onClick={ClickHandler} to={`/csr/${csr.slug}`}>
                                    <div className="icon">
                                        <i className={`fi ${csr.icon}`}></i>
                                    </div>
                                    <h3>{csr.sTitle}</h3>
                                    <p>{csr.description}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CsrSection;