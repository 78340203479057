import React from "react";
import abimg1 from '../../images/about/mission.jpg'
import abimg2 from '../../images/about/vision.jpg'
import abimg3 from '../../images/about/goals.jpg'


const MissionVission = () => {
    return (
        <section className="mission-vision-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="mission-vision-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg1} alt="" />
                                </div>
                                <h3>Our mission</h3>
                                <p>To become a global business leader, by making a difference in people's lives through positive outcomes as a result of experiences provided by our products and services.</p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg2} alt="" />
                                </div>
                                <h3>Our vision</h3>
                                <p>To become a global business leader, by making a difference in people's lives through positive outcomes as a result of experiences provided by our products and services.</p>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={abimg3} alt="" />
                                </div>
                                <h3>Our Goals</h3>
                                <p>To become a global business leader, by making a difference in people's lives through positive outcomes as a result of experiences provided by our products and services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionVission;