import timg1 from '../images/team/paschal.jpg'
import timg2 from '../images/team/IJ.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/bola.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Dr Paschal',
        slug:'Dr-Paschal',
        title:'CEO',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Miss Ijeoma',
        slug:'Miss-Ijeoma',
        title:'Deputy CEO',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Miss Vivian',
        slug:'Miss-Vivian',
        title:'General Manager',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Miss Bola',
        slug:'Miss-Bola',
        title:'Head of Operations/Research',
    }
]

export default Teams;