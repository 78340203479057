import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import MobileMenu from '../MobileMenu/MobileMenu'
import { totalPrice } from "../../utils";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Logo from '../../images/ffsdgroup.png'

const Header2 = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [cartActive, setcartState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const { carts } = props;

    return (
        <header id="header" className={`site-header header-style-2 ${props.hclass}`}>
            <HeaderTopbar />
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/home"><img src={Logo} alt="" /></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <button className="close-navbar"><i className="ti-close"></i></button>

                        <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Home</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/home">Home Default</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-2">Home style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-3">Home style 3</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-4">Home static hero</Link></li>
                                    <li><Link onClick={ClickHandler} to="/home-5">Home static hero box</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Pages</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/about">About</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team-single/Morkal-Akunda">Team Single</Link></li>
                                    <li><Link onClick={ClickHandler} to="/404">404</Link></li>
                                    <li className="menu-item-has-children">
                                        <Link onClick={ClickHandler} to="/">Shop</Link>
                                        <ul className="sub-menu">
                                            <li><Link onClick={ClickHandler} to="/shop">Shop Page</Link></li>
                                            <li><Link onClick={ClickHandler} to="/product-single/Blue-Striped-Shoes">Shop single</Link></li>
                                            <li><Link onClick={ClickHandler} to="/cart">Cart</Link></li>
                                            <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Services</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/services">Service style 1</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services-s2">Service style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-s3">Service style 3</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Market-Research">Market Research</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Corporate-Finance">Corporate Finance</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Advanced-Analytics">Advance Analytics</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Change-Management">Change Management</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Strategy-Marketing">Stragegy & Marketing</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Projects</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/projects">Projects</Link></li>
                                    <li><Link onClick={ClickHandler} to="/projects-s2">Projects style 2</Link></li>
                                    <li><Link onClick={ClickHandler} to="/project-single/Market-Research">Project single</Link></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/">Blog</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/blog">Blog Default</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog full width</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single/Consulting-Success-is-the-most-comprehensive-learning">Blog details default</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/Consulting-Success-is-the-most-comprehensive-learning">Blog details left sidebar</Link></li>
                                    <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/Consulting-Success-is-the-most-comprehensive-learning">Blog details full width</Link></li>
                                </ul>
                            </li>
                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <div className="cart-search-contact">

                        {/* <div className="header-search-form-wrapper">
                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                <form onSubmit={SubmitHandler}>
                                    <div>
                                        <input type="text" className="form-control"
                                            placeholder="Search here..." />
                                        <button type="submit"><i
                                            className="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div> */}

                        <div className="mini-cart">
                            <button className="cart-toggle-btn" onClick={() => setcartState(!cartActive)}>
                                {" "}
                                <i className="fi flaticon-shopping-bag-3"></i>{" "}
                                <span className="cart-count">{carts.length}</span>
                            </button>
                            <div className={`mini-cart-content ${cartActive ? "mini-cart-content-toggle" : ""}`}>
                                {/* <button className="mini-cart-close" onClick={() => setcartState(!cartActive)}><i className="ti-close"></i></button> */}
                                <div className="mini-cart-items">
                                <form onSubmit={SubmitHandler}>
                                <div>
                                                <label htmlFor="username">Username</label>
                                                <input type="text" id="username" placeholder="Enter your username" />
                                            </div>
                                            <div>
                                                <label htmlFor="password">Password</label>
                                                <input type="password" id="password" placeholder="Enter your password" />
                                            </div>
                                    <div>
                                        <input type="text" className="form-control"
                                            placeholder="Search here..." />
                                        <button type="submit"><i
                                            className="ti-search"></i></button>
                                    </div>
                                </form>
                                </div>
                                <div className="mini-cart-action clearfix">
                                <form onSubmit={SubmitHandler}>
                                <div>
                                                <label htmlFor="username">Username</label>
                                                <input type="text" id="username" placeholder="Enter your username" />
                                            </div>
                                            <div>
                                                <label htmlFor="password">Password</label>
                                                <input type="password" id="password" placeholder="Enter your password" />
                                            </div>
                                    <div>
                                        <input type="text" className="form-control"
                                            placeholder="Search here..." />
                                        <button type="submit"><i
                                            className="ti-search"></i></button>
                                    </div>
                                </form>
                                    <span className="mini-checkout-price">Subtotal: <span> ${totalPrice(carts)}</span></span>
                                    <div className="mini-btn">
                                        <Link to="/cart" className="view-cart-btn">View Cart</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>

    )
}

const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};


export default connect(mapStateToProps, { removeFromCart })(Header2);