import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams, useLocation } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import Events from '../../api/events'
// import ServiceTab from './ServiceTab';
import Logo from '../../images/ffsdgroup.png'
import Research from '../../components/Research/Research';
import Departure from '../../components/Departure/Departure';
import Anniversary from '../../components/Anniversary/anniversary';
import Retreat from '../../components/Retreat/retreat';
import Edufair from '../../components/Edufair/edufair';
// import Anniversary from '../../components/Anniversary/Anniversary';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
    e.preventDefault()
}


const EventSinglePage = (props) => {

  const location = useLocation();

  const routes = {
    "/events/10th-Anniversary": <Anniversary />,
    "/events/Pre-departure-Events": <Departure />,
    "/events/Business-Retreat": <Retreat />,
    "/events/Education-Fair": <Edufair />,
  };

    const { slug } = useParams()

    const EventDetails = Events.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={EventDetails.sTitle} pagesub={'Events'} />

            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 order-lg-2 order-1 col-12">
                            <div className="service-single-content">
                                <div className="service-pic">
                                {routes[location.pathname]  }
                                {/* {location.pathname === "/events/10th-Anniversary" ?  <Anniversary /> : <Departure />} */}
                                    
                                    {/* <img src={EventDetails.sImg} alt="" /> */}
                                </div>
                                <h2>{EventDetails.sTitle}</h2>
                                <p>{EventDetails.description}</p>
                                <p>{EventDetails.des2}</p>
                                <p>{EventDetails.des3}</p>
                                <p>{EventDetails.des4}</p>
                                <p>{EventDetails.des5}</p>
                                <p>{EventDetails.des6}</p>
                            </div>
                        </div>
                        <div className="col col-lg-3 order-lg-1 order-2 col-12">
                            <div className="service-sidebar">
                                <div className="widget service-list-widget">
                                    <ul>
                                        <li className="current"><Link to="/events">All Events</Link></li>
                                        {Events.map((event, srv) => (
                                            <li className='current' key={srv}><Link onClick={ClickHandler} to={`/events/${event.slug}`}>{event.sTitle}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                {/* <div className="widget download-widget">
                                    <ul>
                                        <li>
                                        <a href={EventDetails.website} target="_blank" rel="noopener noreferrer"><i className="ti-file"></i>Visit Website</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget contact-widget">
                                    <div>
                                        <h4>Need help?</h4>
                                        <p>We would be glad to profer Tailored solutions to you. Available 24/7 to attend to your needs</p>
                                        <p>Phone: {EventDetails.phone}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default EventSinglePage;
