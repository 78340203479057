import React from "react";
import { Link } from 'react-router-dom'
import Events from '../../api/events'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const EventSection = () => {
    return (
        <section className="services-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="section-title-s3">
                            <span>Events</span>
                            <h2>Some of our Events</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="services-grids clearfix">
                            {Events.map((event, srv) => (
                                <div className="grid" key={srv}>
                                    <Link onClick={ClickHandler} to={`/events/${event.slug}`}>
                                    <div className="icon">
                                        <i className={`fi ${event.icon}`}></i>
                                    </div>
                                    <h3>{event.sTitle}</h3>
                                    <p>{event.description}</p>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EventSection;