import simg from '../images/service-single/img-1.jpg'
import simg2 from '../images/service-single/img-2.jpg'
// import simg3 from '../images/service-single/img-3.jpg'
// import simg4 from '../images/service-single/img-4.jpg'
// import simg5 from '../images/service-single/img-5.jpg'
// import simg6 from '../images/service-single/img-3.jpg'


const Csr = [
    {
        Id: '1',
        sImg:simg,
        sTitle:'Research Grant',
        slug:'Research-Grant',
        description:'The FFSD Small Research Grant is a testament to this commitment, designed to empower academic researchers by providing critical financial support for their scholarly pursuits.',
        des2:'Research is the backbone of progress, and we understand the challenges faced by researchers in accessing the resources required to advance their work. Through this initiative, we aim to bridge this gap, ensuring that Nigerian researchers can contribute meaningfully to global academic discourse and local problem-solving.',
        des3:'The grant is tailored to support diverse academic fields, encouraging interdisciplinary research and fostering innovative solutions to pressing societal challenges. By providing sufficient funding, the FFSD Research Grant enables researchers to acquire essential materials, access advanced technologies, and dedicate the time needed for groundbreaking discoveries.',
        des4:"Our vision is not only to nurture academic excellence but also to inspire researchers to explore solutions that address Nigeria's unique challenges, from health and education to technology and sustainable development.",
        des5:'Beyond financial support, we aim to create a platform that encourages collaboration among Nigerian researchers, fostering a culture of mentorship and shared learning. Each grant recipient becomes part of a growing network of scholars dedicated to enriching the intellectual fabric of the nation.',
        des6:'At FFSD, we firmly believe that by investing in research, we are investing in the future—fueling innovation that drives economic growth, enhances quality of life, and establishes Nigeria as a hub for academic and technological advancements.',
        icon:'flaticon-gift',
        website: '',
        phone: '+2348012345678'
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle:'Charity',
        slug:'Charity',
        description:'At FFSD Group, we recognize that true success extends far beyond financial achievements. Over the past three years, we have been steadfast in our commitment to making a meaningful difference in the communities we serve.',
        des2:'Our charity initiatives are rooted in the belief that businesses have a moral obligation to contribute to societal well-being. Through thoughtful and impactful programs, we aim to uplift individuals and families, addressing immediate needs while also fostering long-term empowerment.',
        des3:'Our charitable efforts encompass a wide range of initiatives, from providing essential resources to underserved communities to implementing programs that enhance access to education, healthcare, and basic necessities.',
        des4:'Each year, we identify key areas where our support can create the greatest impact, focusing on vulnerable populations and communities that often fall through the cracks of larger aid efforts.',
        des5:'Whether it’s distributing school supplies to children in rural areas, funding community health programs, or supporting shelters for displaced families, our initiatives are carefully designed to address real-world challenges.',
        des6:'At the heart of our charity work is the belief that change is most effective when it is collaborative. We actively partner with local organizations, community leaders, and volunteers to ensure that our efforts are well-informed and sustainable. By working together, we aim to not only provide immediate relief but also empower individuals to overcome adversity and build better futures for themselves and their families.',
        des7:'Through our consistent charitable efforts, FFSD has become more than a corporate entity; we are a trusted ally in the fight for equity and opportunity. Our unwavering dedication to social responsibility is not just a corporate policy—it is a core value that drives every aspect of our operations.',
        des8:'At FFSD, we are committed to being a force for good, ensuring that our success is shared and that our legacy is defined by the positive change we bring to the world.',
        website: '',
        phone: '+2348012345678',
        icon:'flaticon-suitcase'
    }
]    

export default Csr;