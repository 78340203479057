import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import Logo from '../../images/ffsdgroup.png'
import EventSection from '../../components/EventSection/EventSection';


const EventPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Events'} pagesub={'Events'}/> 
            <EventSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default EventPage;

