import React from 'react'
import abimg from '../../images/about-3.jpg'
import VideoModal from '../ModalVideo/VideoModal'


const About = (props) => {
    return (
        <section className="about-section-s2">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 order-lg-1 order-2 col-12">
                        <div className="img-holder">
                            {/* <iframe width="450" height="450" src="https://www.youtube.com/embed/nAHnlJwMVTM" title="FFSD Business Retreat" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                            {/* <video 
                                src={abVideo} 
                                autoPlay 
                                muted 
                                loop 
                                playsInline 
                                className="experience-video"
                            ></video> */}
                            <img src={abimg} alt="" />
                            <div className="experience-text">
                                12+ Years of <span>Experience</span>
                            </div>
                            <VideoModal />
                        </div>
                    </div>
                    <div className="col col-lg-6 order-lg-2 order-1 col-12">
                        <div className="section-title-s2">
                            {/* <span>We are consulting agency!</span> */}
                            <h2>At FFSD Group, we’re dedicated to <br />helping you reach your goals</h2>
                        </div>
                        <div className="about-content">
                            <h4>FFSD is committed to providing customers with exceptional services at competitive prices.</h4>
                            <p>The most enriching and unforgettable study abroad and travel experiences possible. Looking forward to immersing yourself in a new culture, learning a new language, or simply exploring new destinations and making lifelong memories, we are here to make it happen just for you.</p>
                            <p>Our team is passionate about travels and education, and we strive to create customized programs that meet the unique needs and interests of each of our clients.</p>
                            <p>With our expertise and dedication, you can trust us to provide you with an exceptional experience that will broaden your horizons and transform your life.</p>
                            {/* <h5>Phone: 012345645, +6546521145</h5> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;